// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#single-spa-application\\:\\@roambee\\/client-bee {
	#main-layout {
		width: 100%;
		&.sidenav-normal {
			width: calc(100% - 240px);
			margin-left: 240px;
		}
		&.sidenav-small {
			width: calc(100% - 80px);
			margin-left: 80px;
		}
	}
	#container-layout {
		padding: 24px;
		padding-top: 84px;
	}
}
`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;CACC;EACC,WAAW;EACX;GACC,yBAAyB;GACzB,kBAAkB;EACnB;EACA;GACC,wBAAwB;GACxB,iBAAiB;EAClB;CACD;CACA;EACC,aAAa;EACb,iBAAiB;CAClB;AACD","sourcesContent":["#single-spa-application\\:\\@roambee\\/client-bee {\n\t#main-layout {\n\t\twidth: 100%;\n\t\t&.sidenav-normal {\n\t\t\twidth: calc(100% - 240px);\n\t\t\tmargin-left: 240px;\n\t\t}\n\t\t&.sidenav-small {\n\t\t\twidth: calc(100% - 80px);\n\t\t\tmargin-left: 80px;\n\t\t}\n\t}\n\t#container-layout {\n\t\tpadding: 24px;\n\t\tpadding-top: 84px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
