/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from "react";

import "./App.scss";
// @ts-ignore
import { EventEmitter } from "@roambee/client-utility";

import Bees from "./pages/Bee";

function App() {
	const [isWide, setWide] = useState(true);

	useEffect(() => {
		EventEmitter.addListener("toggleSideNav", (eventData) => {
			setWide(eventData);
		});

		return () => {
			EventEmitter.removeListener("toggleSideNav", (eventData) => {
				setWide(eventData);
			});
		};
	}, []);

	useEffect(() => {
		//if one of the accordion menu is open and active close the other menu accordions
		window.addEventListener("resize", () => {
			if (window.innerWidth > 400 && window.innerWidth < 1200) {
				const container = document.querySelector("#main-layout");
				container?.classList.add("sidenav-small");
			} else {
				const container = document.querySelector("#main-layout");
				container?.classList.remove("sidenav-small");
			}
		});
	}, []);

	return (
		<section id="main-layout" className={isWide ? "sidenav-normal" : "sidenav-small"}>
			<section id="container-layout">
				<Bees></Bees>
			</section>
		</section>
	);
}

export default App;
