/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useCallback, useEffect, useMemo, useState } from "react";

// @ts-ignore
import { DataTable, DrawerPanel } from "@roambee/client-styleguide";
// @ts-ignore
import { API, EventEmitter, generateQueryParams, getAuthUser } from "@roambee/client-utility";
import { Bee_Table_Columns, Bee_Table_Filter_Operations, Bee_Table_Restricted_Columns } from "../configs/BeeTableConfig";
import { API_PATH } from "../configs/APIConfig";
import { useSearchParams } from "react-router-dom";

let tableConfiguration: any = localStorage.getItem("bee_table");
if (tableConfiguration && tableConfiguration.length) {
	tableConfiguration = JSON.parse(tableConfiguration);
}

const parseFilterString = (filterString) => {
	if (filterString && filterString.length) {
		return filterString.split(" and ").map((filter) => {
			const [id, operation, value] = filter.match(/(\w+)\s(\w+)\s'(.+)'/).slice(1);
			return { id, value };
		});
	} else {
		return null;
	}
};

function BeeTable() {
	let urlFilters: any = {};
	const [searchParams] = useSearchParams();
	if (searchParams && searchParams.get("filter")) {
		urlFilters = JSON.parse(searchParams.get("filter"));
	}

	// get user
	const user = getAuthUser();

	const [data, setData] = useState({ data: [], offset: 0, total: 0, size: 10 });
	const [loading, setLoading] = useState(true);
	const [open, setOpen] = useState(false);

	// table
	const staticColumns = useMemo(() => Bee_Table_Columns, []);
	const [dynamicColumns, setDynamicColumns] = useState([]);
	const [columnFilters, setColumnFilters] = useState(parseFilterString(urlFilters?.filter) || parseFilterString(tableConfiguration?.filter) || [{ id: "active", value: "1" }]);
	const [columnFilterModes, setColumnsFilterModes] = useState();
	const [sorting, setSorting] = useState(urlFilters?.sort || tableConfiguration?.sort || []);
	const [pagination, setPagination] = useState({
		pageIndex: urlFilters?.offset || tableConfiguration?.offset || 0,
		pageSize: urlFilters?.size || tableConfiguration?.size || 10
	});
	const [filterConfigs, setFilterConfigs] = useState([
		{
			label: "All",
			variant: "secondary",
			size: "small",
			onClick: () => handleFilterClick("All")
		},
		{
			label: "Active",
			variant: "primary",
			size: "small",
			onClick: () => handleFilterClick("Active")
		},
		{
			label: "Inactive",
			variant: "secondary",
			size: "small",
			onClick: () => handleFilterClick("Inactive")
		}
		// Add more filter configurations as needed
	]);
	const [toolbarConfigs, setToolbarConfigs] = useState([
		{
			label: "All filters",
			variant: "secondary",
			size: "small",
			onClick: () => setOpen(true)
		},
		{
			label: "Share",
			variant: "secondary",
			size: "small",
			onClick: () => navigator.clipboard.writeText(window.location.origin + window.location.pathname + `?filter=${localStorage.getItem("bee_table")}`)
		}
	]);

	const combinedColumns = useMemo(() => {
		if (user && user.role_names) {
			const isSuperAdmin = user.role_names.includes("super-admin") || user.role_names.includes("roambee-admin");

			if (isSuperAdmin) {
				return [...staticColumns, ...dynamicColumns];
			} else {
				const filteredStaticColumns = staticColumns.filter((column: any) => !Bee_Table_Restricted_Columns.includes(column.accessorKey));
				return [...filteredStaticColumns, ...dynamicColumns];
			}
		}

		return [...staticColumns, ...dynamicColumns];
	}, [staticColumns, dynamicColumns, user]);

	const fetchData = useCallback(() => {
		setLoading(true);
		const params = {
			offset: pagination.pageIndex || 0,
			size: pagination.pageSize || 10,
			fields: "all",
			filter: generateFilterString(columnFilters),
			sort: sorting
		};
		const queryString = generateQueryParams(params);
		API("GET", `${API_PATH.BEES}?${queryString}`)
			.then((result) => {
				setData(result);
				setLoading(false);
			})
			.catch((error) => {
				console.error("API Error:", error);
				EventEmitter.emit("showSnackbar", {
					id: "error",
					leftIcon: true,
					message: error?.message || "Something went wrong!",
					variant: "error",
					ariaLabel: "close button",
					position: "top-right"
				});
				setLoading(false);
			});

		localStorage.setItem("bee_table", JSON.stringify(params));
	}, [columnFilters, sorting, pagination]);

	useEffect(() => {
		const params = {
			rbql: {
				filters: [
					{ name: "account_id", value: user?.account?.id },
					{ name: "type", value: "bee" },
					{ name: "active", value: 1 }
				],
				other_options: { return_all: true }
			}
		};
		const queryString = generateQueryParams(params);
		API("GET", `${API_PATH.AUTO_CRUD_CUSTOM_FIELDS}?${queryString}`)
			.then((result) => {
				const apiColumns = result.data.map((item) => ({
					accessorKey: item.es_identifier,
					header: item.placeholder,
					isSortable: true,
					isSearchable: true,
					filterVariant: item.datatype === "select" ? "select" : undefined,
					filterSelectOptions: item.values ? JSON.parse(item.values).map((val) => val.value) : undefined
				}));
				setDynamicColumns(apiColumns);
				setLoading(false);
			})
			.catch((error) => {
				console.error("API Error:", error);
				EventEmitter.emit("showSnackbar", {
					id: "error",
					leftIcon: true,
					message: error?.message || "Something went wrong!",
					variant: "error",
					ariaLabel: "close button",
					position: "top-right"
				});
				setLoading(false);
			});
	}, [user]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const handleFilterClick = (label) => {
		// Update variant in filterConfigs
		const updatedFilters = filterConfigs.map((filter) => ({
			...filter,
			variant: filter.label === label ? "primary" : "secondary"
		}));
		setFilterConfigs(updatedFilters);

		// Update columnFilters based on label
		if (label === "All") {
			// Remove filter with id: "active"
			setColumnFilters((prevFilters) => prevFilters.filter((filter) => filter.id !== "active"));
		} else {
			// Check if there is already a filter with id: "active"
			const existingFilterIndex = columnFilters.findIndex((filter) => filter.id === "active");

			if (existingFilterIndex !== -1) {
				// Update existing filter with id: "active"
				const updatedColumnFilters = [...columnFilters];
				updatedColumnFilters[existingFilterIndex].value = label === "Active" ? "1" : "0";
				setColumnFilters(updatedColumnFilters);
			} else {
				// Add new filter with id: "active"
				setColumnFilters((prevFilters) => [...prevFilters, { id: "active", value: label === "Active" ? "1" : "0" }]);
			}
		}
	};

	const generateFilterString = (filters) => {
		return filters
			.filter((filter) => filter.value !== null && filter.value !== undefined && filter.value.length !== 0)
			.map((filter) => {
				const operations = Bee_Table_Filter_Operations[filter.id];
				if (!operations) return "";

				// Split operations by '|' to handle multiple operations
				return operations
					.split("|")
					.map((operation) => `${filter.id} ${operation} '${filter.value}'`)
					.join(" or ");
			})
			.filter((filter) => filter !== "")
			.join(" and ");
	};

	return (
		<section id="bee-table">
			<DataTable title="Bees" columns={combinedColumns} data={data?.data || []} isLoading={loading} columnFilters={columnFilters} onColumnFiltersChange={setColumnFilters} columnFilterFns={columnFilterModes} onColumnFilterFnsChange={setColumnsFilterModes} sorting={sorting} onSortingChange={setSorting} pagination={pagination} onPaginationChange={setPagination} rowCount={data.total} filterConfigs={filterConfigs} toolbarConfigs={toolbarConfigs} />
			<DrawerPanel open={open} setOpen={setOpen} title="Filter">
				All Filters Here
			</DrawerPanel>
		</section>
	);
}
export default BeeTable;
