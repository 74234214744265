import BeeTable from "../components/BeeTable";

function Bees() {
	return (
		<section id="bee">
			<BeeTable></BeeTable>
		</section>
	);
}

export default Bees;
