/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { formatDateTime } from "@roambee/client-utility";
// @ts-ignore
import { Tag, AppThemes } from "@roambee/client-styleguide";

const getCarriers = () => {
	try {
		// TODO : get this from rb-of
		const carriers = ["Aeris-Global", "Onomondo", "Aeris-AT&T", "Airtel", "floLIVE", "Maxis", "Orange", "Other", "Sprint", "Telefonica", "Telenor", "Telkomsel", "Tmobile", "Vodafone"];
		return carriers;
	} catch (e) {
		return [];
	}
};

const getDevices = () => {
	try {
		// TODO : get this rom rb-of
		const devices = ["Android", "BLE", "BLESensor", "BLELocation", "BeeAware", "BeeAware-GSM", "BeeAware-LTE-XL", "BeeLock", "BeeRouter", "BeeSense", "BeeSenseAir", "GL200", "GL300", "GL500", "GL53MG", "IEThings", "iOS", "MOOG3", "MOOG4", "Myriota", "OBD2", "OCR_SONAR", "QTS110", "SENSOR", "SFX-ALPS", "Wiliot", "TOPFLY_SOLAR", "BeeSenseSolar500", "BeeTrac300", "BeeTracSolar400", "GL53", "BeeSenseFlex", "BeeLockSolar", "BeeTracFit", "BeeAssetFit"];
		return devices;
	} catch (e) {
		return [];
	}
};

const getCommunications = () => {
	return ["OK", "NOCOMM", "NONTWK"];
};

const getBleState = () => {
	return ["true", "false"];
};

const getAMOState = () => {
	return ["true", "false"];
};
const getBatteryTypes = () => {
	// TODO : get this from rb_of
	return ["CR123", "Li-ion", "NLB", "CR2032"];
};
const getBatteryStatus = () => {
	// TODO : get this from rb_of
	return ["Normal", "Charging", "Low", "Drained"];
};
const Status_Colors = {
	Normal: {
		bgColor: AppThemes.light.colors.supportSuccess50,
		color: AppThemes.light.colors.supportSuccess400
	},
	Charging: {
		bgColor: AppThemes.light.colors.purpleSunset50,
		color: AppThemes.light.colors.purpleSunset400
	},
	Low: {
		bgColor: AppThemes.light.colors.supportWarning50,
		color: AppThemes.light.colors.supportWarning400
	},
	Drained: {
		bgColor: AppThemes.light.colors.supportError50,
		color: AppThemes.light.colors.supportError400
	}
};
const Communication_Colors = {
	OK: {
		bgColor: AppThemes.light.colors.supportSuccess50,
		color: AppThemes.light.colors.supportSuccess400
	},
	NOCOMM: {
		bgColor: AppThemes.light.colors.supportWarning50,
		color: AppThemes.light.colors.supportWarning400
	},
	NONTWK: {
		bgColor: AppThemes.light.colors.supportError50,
		color: AppThemes.light.colors.supportError400
	}
};

const Bee_Table_Restricted_Columns = ["sim_number", "last_clear_timestamp", "account_name", "imsi", "imei", "bee_version", "firmware", "listener_hostname", "last_message_protocol", "communication", "battery_type", "last_known_timestamp", "dev_src"];

const Bee_Table_Columns = [
	{
		accessorKey: "account_name",
		header: "Account Name",
		isSortable: true,
		isSearchable: true,
		enableEditing: false
	},
	{
		accessorKey: "bee_name",
		header: "Bee Name",
		isSortable: true,
		isSearchable: true,
		enableEditing: true
	},
	{
		accessorKey: "bee_number",
		header: "Bee Number",
		isSortable: true,
		isSearchable: true,
		enableEditing: false
	},
	{
		accessorKey: "device_type",
		header: "Type",
		isSortable: true,
		isSearchable: true,
		filterVariant: "select",
		filterSelectOptions: getDevices(),
		enableEditing: false
	},
	{
		accessorKey: "shipment_name",
		header: "Status",
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		Cell: ({ renderedCellValue, row }) => {
			return row.original.shipment_name && row.original.shipment_uuid ? (
				<a href={`${process.env.CLIENT_HC10_URL}/shipments/details/${row.original.shipment_uuid}`} target="_blank" rel="noopener noreferrer">
					{renderedCellValue}
				</a>
			) : (
				<Tag label="Unallocated" backgroundColor={AppThemes.light.colors.purpleSunset50} color={AppThemes.light.colors.purpleSunset400} />
			);
		}
	},
	{
		accessorKey: "created_date",
		header: "Added",
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => formatDateTime(renderedCellValue, "LLL dd, yyyy hh:mm a"),
		enableEditing: false
	},
	{
		accessorKey: "last_known_location",
		header: "Last Known Location",
		isSortable: true,
		isSearchable: true,
		enableEditing: false
	},
	{
		accessorKey: "imei",
		header: "IMEI",
		isSortable: true,
		isSearchable: true,
		enableEditing: false
	},
	{
		accessorKey: "mobile_number",
		header: "Mobile Number",
		isSortable: true,
		isSearchable: true,
		enableEditing: false
	},
	{
		accessorKey: "sim_number",
		header: "SIM Number",
		isSortable: true,
		isSearchable: true,
		enableEditing: false
	},
	{
		accessorKey: "carrier",
		header: "Carrier",
		isSortable: true,
		isSearchable: true,
		filterVariant: "select",
		filterSelectOptions: getCarriers(),
		enableEditing: false
	},
	{
		accessorKey: "bee_version",
		header: "Bee Version",
		isSortable: true,
		isSearchable: true,
		enableEditing: false
	},
	{
		accessorKey: "firmware",
		header: "Firmware",
		isSortable: true,
		isSearchable: true,
		enableEditing: false
	},
	{
		accessorKey: "last_message_timestamp",
		header: "Last Message",
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => formatDateTime(renderedCellValue, "LLL dd, yyyy hh:mm a"),
		enableEditing: false
	},
	{
		accessorKey: "listener_hostname",
		header: "Last Message Server",
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		Cell: ({ row }) => {
			const bState = row.original.state ? JSON.parse(row.original.state) : {};
			return bState?.last_msg?.msg?.listener_hostname || "-";
		}
	},
	{
		accessorKey: "last_message_protocol",
		header: "Protocol",
		isSortable: true,
		isSearchable: true,
		enableEditing: false
	},
	{
		accessorKey: "battery_remaining_days",
		header: "Battery",
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		Cell: ({ row }) => {
			const batt = row.original && row.original.battery_remaining_days && row.original.battery_remaining_days !== 0 ? Math.round(row.original.battery_remaining_days) : 0;
			const battPercent = row.original.battery_remaining_percentage ? row.original.battery_remaining_percentage : 0;
			return `${batt} days (${battPercent}%)`;
		}
	},
	{
		accessorKey: "bee_lock",
		header: "BeeLock",
		isSortable: true,
		isSearchable: true,
		enableEditing: false
	},
	{
		accessorKey: "device_mode",
		header: "Mode",
		isSortable: true,
		isSearchable: true,
		enableEditing: false
	},
	{
		accessorKey: "bleEnable",
		header: "BLE Enabled",
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		filterVariant: "select",
		filterSelectOptions: getBleState()
	},
	{
		accessorKey: "amo_state",
		header: "AMO State",
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		filterVariant: "select",
		filterSelectOptions: getAMOState()
	},
	{
		accessorKey: "battery_state",
		header: "Battery State",
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		filterVariant: "select",
		filterSelectOptions: getBatteryStatus(),
		Cell: ({ renderedCellValue }) => {
			if (!renderedCellValue) {
				return "-";
			}
			const { bgColor, color } = Status_Colors[renderedCellValue] || {};
			return <Tag label={renderedCellValue} backgroundColor={bgColor} color={color} />;
		}
	},
	{
		accessorKey: "battery_remaining_percentage",
		header: "Battery Remaining Percentage",
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? `${renderedCellValue}%` : "-")
	},
	{
		accessorKey: "battery_type",
		header: "Battery Type",
		isSortable: true,
		isSearchable: true,
		enableEditing: false,
		filterVariant: "select",
		filterSelectOptions: getBatteryTypes()
	},
	{
		accessorKey: "communication",
		header: "Communication",
		isSortable: true,
		isSearchable: true,
		filterVariant: "select",
		enableEditing: false,
		filterSelectOptions: getCommunications(),
		Cell: ({ renderedCellValue }) => {
			if (!renderedCellValue) {
				return "-";
			}
			const { bgColor, color } = Communication_Colors[renderedCellValue] || {};
			return <Tag label={renderedCellValue} backgroundColor={bgColor} color={color} />;
		}
	},
	{
		accessorKey: "temp",
		header: "Temperature",
		isSortable: true,
		isSearchable: true,
		enableEditing: false
	},
	{
		accessorKey: "pressure",
		header: "Pressure",
		isSortable: true,
		isSearchable: true,
		enableEditing: false
	},
	{
		accessorKey: "humidity",
		header: "Humidity",
		isSortable: true,
		isSearchable: true,
		enableEditing: false
	},
	{
		accessorKey: "ambient",
		header: "Ambient",
		isSortable: true,
		isSearchable: true,
		enableEditing: false
	},
	{
		accessorKey: "tiltshock",
		header: "Tilt",
		isSortable: true,
		isSearchable: true,
		enableEditing: false
	},
	{
		accessorKey: "altitude",
		header: "Altitude",
		isSortable: true,
		isSearchable: true,
		enableEditing: false
	},
	{
		accessorKey: "tamper",
		header: "Tamper",
		isSortable: true,
		isSearchable: true,
		enableEditing: false
	},
	{
		accessorKey: "shock",
		header: "Shock",
		isSortable: true,
		isSearchable: true,
		enableEditing: false
	},
	{
		accessorKey: "last_known_timestamp",
		header: "Last Known Time",
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => formatDateTime(renderedCellValue, "LLL dd, yyyy hh:mm a"),
		enableEditing: false
	},
	{
		accessorKey: "hub_name",
		header: "Hub",
		isSortable: true,
		isSearchable: true,
		enableEditing: false
	},
	{
		accessorKey: "ping_frequency",
		header: "PRF",
		isSortable: true,
		isSearchable: true,
		enableEditing: false
	},
	{
		accessorKey: "beezone_label",
		header: "BeeZone",
		width: 16,
		isSortable: true,
		isSearchable: true,
		enableEditing: false
	},
	{
		accessorKey: "VIN_number",
		header: "VIN",
		width: 16,
		isSortable: true,
		isSearchable: true,
		enableEditing: false
	},
	{
		accessorKey: "control_window",
		header: "Control Window",
		width: 16,
		isSortable: true,
		isSearchable: true,
		enableEditing: false
	},
	{
		accessorKey: "offset",
		header: "Offset",
		width: 16,
		isSortable: true,
		isSearchable: true,
		enableEditing: false
	},
	{
		accessorKey: "last_clear_timestamp",
		header: "Last clear time",
		width: 16,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => formatDateTime(renderedCellValue, "LLL dd, yyyy hh:mm a"),
		enableEditing: false
	},
	{
		accessorKey: "imsi",
		header: "IMSI",
		width: 16,
		isSortable: true,
		isSearchable: true,
		enableEditing: false
	},
	{
		accessorKey: "pharma_config",
		header: "Pharma Config",
		width: 16,
		isSortable: true,
		isSearchable: true,
		enableEditing: false
	},
	{
		accessorKey: "router_last_reset_timestamp",
		header: "Router last reset time",
		width: 16,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => formatDateTime(renderedCellValue, "LLL dd, yyyy hh:mm a"),
		enableEditing: false
	},
	{
		accessorKey: "dev_src",
		header: "Dev Src",
		width: 16,
		isSortable: true,
		isSearchable: true
	}
];

const Bee_Table_Filter_Operations = {
	bee_name: "has",
	bee_number: "has",
	imei: "has",
	account_name: "eq|has",
	mobile_number: "has",
	sim_number: "has",
	carrier: "eq",
	bee_version: "has",
	firmware: "has",
	last_message_timestamp: "has",
	listener_hostname: "has",
	last_message_protocol: "has",
	battery_remaining_days: "eq",
	battery_remaining_percentage: "eq",
	bee_lock: "has",
	device_mode: "has",
	bleEnable: "eq",
	amo_state: "eq",
	battery_state: "has",
	battery_type: "eq",
	communication: "eq",
	temp: "eq",
	pressure: "eq",
	humidity: "eq",
	ambient: "eq",
	tiltshock: "eq",
	altitude: "eq",
	tamper: "has",
	shock: "eq",
	shipment_name: "has",
	last_known_location: "has",
	last_known_timestamp: "has",
	hub_name: "has",
	ping_frequency: "eq",
	beezone_label: "has",
	VIN_number: "has",
	control_window: "has",
	offset: "has",
	last_clear_timestamp: "has",
	imsi: "has",
	pharma_config: "has",
	router_last_reset_timestamp: "has",
	dev_src: "has",
	device_type: "eq",
	active: "eq"
	// Add more column mappings here
};

export { Bee_Table_Columns, Bee_Table_Filter_Operations, Bee_Table_Restricted_Columns };
